import { AttemptStatus } from '../../../../../typings/EventAttempt.interface';
import { TransformationExecutionLogLevel } from '../../../../../typings/Transformation.interface';
import { EventListFiltersProps } from '../../typings/EventList.interface';
import { RequestListFiltersProps } from '../../typings/RequestList.interface';

import { setMilliseconds } from 'date-fns';

export type FilterOperator =
  | 'equals'
  | 'notEquals'
  | 'gt'
  | 'gte'
  | 'lt'
  | 'lte'
  | 'contains'
  | 'notContains'
  | 'set'
  | 'notSet';

export type PresetDateRange = 'Last 30 days' | 'Last 7 days' | 'Last minute';

export type DateRange = PresetDateRange | [string, string];

export interface Filter {
  member: string;
  operator: FilterOperator;
  values: Array<string | null>;
}

export interface TimeDimension {
  dimension: string;
  granularity?: Granularity;
  dateRange?: PresetDateRange | string[];
}

export const status_to_value = {
  failed: 'FAILED',
  paused: 'HOLD',
  successful: 'SUCCESSFUL',
  pending: 'QUEUED',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Query = { [k: string]: any };

export interface AttemptFilterProps {
  destination_id?: string[];
  date?: { min?: string; max?: string };
}

export type Granularity = 'month' | 'day' | 'hour' | 'minute' | 'second';

// Using START_DATE as an initial date, but if min is not specified in filtersto using selected date - 30.
//We might want to move t
export const START_DATE = '2020-01-01T00:00:00.000';

export const buildFilterField = (
  member: string,
  operator: FilterOperator,
  values: Array<string | null> = [],
): Filter => ({
  member,
  operator,
  values,
});

export const buildTimeDimension = (
  dimension: string,
  granularity?: Granularity,
  dateRange?: DateRange,
): TimeDimension => ({
  dimension,
  ...(granularity && { granularity }),
  ...(dateRange && { dateRange }),
});

export const toISO = (date: string | Date) => {
  let input = date;

  if (typeof input === 'string') {
    input = new Date(input);
  }
  const localISOTime = setMilliseconds(new Date(input as any), 0)
    .toISOString()
    .slice(0, -1);

  return localISOTime;
};

export interface QueryParams {
  filters?: EventListFiltersProps | RequestListFiltersProps | AttemptFilterProps;
  granularity?: Granularity;
  dimentions?: string[];
  time_dimention?: string;
  measure?: string;
}

/**
 * This function builds the query to send to cubejs.
 * If granularity is set, but filters contain non-supported
 * parameters for aggregation, this function returns null.
 *
 * If the query has headers or a body filter,
 * query is not supported altogether, and therefore function returns null
 *
 * @param filters
 * @param most_recent_event_ts
 * @param granularity
 * @returns
 */

const CUBE_MODELS: Record<
  | 'EventRequestsTransformations'
  | 'Events'
  | 'EventsRate'
  | 'Requests'
  | 'IgnoredEvents'
  | 'Attempts'
  | 'EventsWithIssues'
  | 'QueueDepth'
  | 'EventsPendingTimeseries',
  {
    timeDimentions: string[];
    isInvalid?: (filters: object) => boolean;
    dimentions: { [key: string]: string };
    applyFilters: (query: Query, model: string, filters: object) => Query;
  }
> = {
  Requests: {
    timeDimentions: ['createdAt', 'ingestedAt'],
    isInvalid: (filters: RequestListFiltersProps) => {
      if (
        filters.request?.headers ||
        filters.request?.body ||
        filters.request?.parsed_query ||
        filters.request?.path ||
        filters.search_term
      ) {
        return true;
      }
      return false;
    },
    dimentions: {
      rejectionCause: 'Requests.rejectionCause',
      source_id: 'Requests.sourceId',
      status: 'Requests.status',
    },
    applyFilters: (query, model, filters: RequestListFiltersProps) => {
      if (filters.bulk_retry_id && filters.bulk_retry_id.length > 0) {
        query.filters.push(
          buildFilterField(
            `${model}.bulkRetryIds`,
            'equals',
            typeof filters.bulk_retry_id === 'string'
              ? [filters.bulk_retry_id]
              : filters.bulk_retry_id,
          ),
        );
      }

      if (filters.source_id && filters.source_id.length > 0) {
        query.filters.push(buildFilterField(`${model}.sourceId`, 'equals', filters.source_id));
      }
      if (filters.ignored_count) {
        if (filters.ignored_count?.min) {
          query.filters.push(
            buildFilterField(`${model}.ignoredCount`, 'gte', [filters.ignored_count.min]),
          );
        }

        if (filters.ignored_count?.max) {
          query.filters.push(
            buildFilterField(`${model}.ignoredCount`, 'lte', [filters.ignored_count.max]),
          );
        }
      }
      if (filters.events_count) {
        if (filters.events_count?.min) {
          query.filters.push(
            buildFilterField(`${model}.eventsCount`, 'gte', [filters.events_count.min]),
          );
        }

        if (filters.events_count?.max) {
          query.filters.push(
            buildFilterField(`${model}.eventsCount`, 'lte', [filters.events_count.max]),
          );
        }
      }
      if (filters.cli_events_count) {
        if (filters.cli_events_count?.min) {
          query.filters.push(
            buildFilterField(`${model}.cliEventsCount`, 'gte', [filters.cli_events_count.min]),
          );
        }

        if (filters.cli_events_count?.max) {
          query.filters.push(
            buildFilterField(`${model}.cliEventsCount`, 'lte', [filters.cli_events_count.max]),
          );
        }
      }
      if (filters.status === 'accepted') {
        // Push a filter to include both null and empty string values for rejectionCause.
        // TODO: This is temporary until after the switch over to the new avro schema table in CH in which can null can be removed.
        query.filters.push(buildFilterField(`${model}.rejectionCause`, 'equals', [null, '']));
      } else if (
        filters.status === 'rejected' &&
        (!filters.rejection_cause || filters.rejection_cause?.length < 1)
      ) {
        // Push a filter to exclude both null and empty string values for rejectionCause.
        // TODO: This is temporary until after the switch over to the new avro schema table in CH in which can null can be removed.
        query.filters.push(buildFilterField(`${model}.rejectionCause`, 'notEquals', [null, '']));
      } else if (filters.rejection_cause && filters.rejection_cause.length > 0) {
        query.filters.push(
          buildFilterField(`${model}.rejectionCause`, 'equals', filters.rejection_cause),
        );
      }

      return query;
    },
  },
  Events: {
    timeDimentions: ['createdAt'],
    isInvalid: (filters: EventListFiltersProps) => {
      if (
        filters.request?.headers ||
        filters.request?.body ||
        filters.request?.parsed_query ||
        filters.request?.path ||
        filters.search_term
      ) {
        return true;
      }
      return false;
    },
    dimentions: {
      status: 'Events.status',
      webhook_id: 'Events.webhookId',
    },
    applyFilters: (query, model, filters: EventListFiltersProps) => {
      if (filters.response_status) {
        if (Array.isArray(filters.response_status)) {
          query.filters.push(
            buildFilterField(`${model}.responseStatus`, 'equals', filters.response_status),
          );
        } else {
          if (filters.response_status?.min) {
            query.filters.push(
              buildFilterField(`${model}.responseStatus`, 'gte', [filters.response_status.min]),
            );
          }

          if (filters.response_status?.max) {
            query.filters.push(
              buildFilterField(`${model}.responseStatus`, 'lte', [filters.response_status.max]),
            );
          }
        }
      }

      if (filters.attempts) {
        if (filters.attempts?.min) {
          query.filters.push(buildFilterField(`${model}.attempts`, 'gte', [filters.attempts.min]));
        }

        if (filters.attempts?.max) {
          query.filters.push(buildFilterField(`${model}.attempts`, 'lte', [filters.attempts.max]));
        }
      }

      if (filters.error_code && filters.error_code.length > 0) {
        query.filters.push(buildFilterField('Events.errorCode', 'equals', filters.error_code));
      }

      if (filters.bulk_retry_id && filters.bulk_retry_id.length > 0) {
        query.filters.push(
          buildFilterField(
            'Events.bulkRetryIds',
            'equals',
            typeof filters.bulk_retry_id === 'string'
              ? [filters.bulk_retry_id]
              : filters.bulk_retry_id,
          ),
        );
      }

      if (filters.issue_id && filters.issue_id.length > 0) {
        query.filters.push(
          buildFilterField(
            'Events.issueIds',
            'equals',
            typeof filters.issue_id === 'string' ? [filters.issue_id] : filters.issue_id,
          ),
        );
      }

      // Disallow granularity setting if status is set
      if (filters.status && filters.status.length > 0) {
        query.filters.push(
          buildFilterField(
            `${model}.status`,
            'equals',
            filters.status && filters.status.map((status) => status_to_value[status] || status),
          ),
        );
      }

      if (filters.webhook_id && filters.webhook_id.length > 0) {
        query.filters.push(buildFilterField(`${model}.webhookId`, 'equals', filters.webhook_id));
      }

      if (filters.source_id && filters.source_id.length > 0) {
        query.filters.push(buildFilterField(`${model}.sourceId`, 'equals', filters.source_id));
      }

      if (filters.destination_id && filters.destination_id.length > 0) {
        query.filters.push(
          buildFilterField(`${model}.destinationId`, 'equals', filters.destination_id),
        );
      }

      //CLI
      if (filters.cli_user_id) {
        if (Array.isArray(filters.cli_user_id) && filters.cli_user_id.length > 0) {
          query.filters.push(buildFilterField(`${model}.cliUserId`, 'equals', filters.cli_user_id));
        } else if (typeof filters.cli_user_id === 'string' && filters.cli_user_id === 'any') {
          query.filters.push(buildFilterField(`${model}.cliId`, 'set'));
        }
      } else {
        query.filters.push(buildFilterField(`${model}.cliId`, 'notSet'));
      }

      if (filters.cli_id) {
        if (Array.isArray(filters.cli_id)) {
          query.filters.push(buildFilterField(`${model}.cliId`, 'equals', filters.cli_id));
        } else if (typeof filters.cli_id === 'string') {
          query.filters.push(buildFilterField(`${model}.cliId`, 'equals', [filters.cli_id]));
        }
      }
      return query;
    },
  },
  EventsRate: {
    dimentions: {},
    timeDimentions: ['createdAt'],
    applyFilters: (query) => query,
  },
  EventRequestsTransformations: {
    timeDimentions: ['createdAt'],
    dimentions: {
      log_level: 'EventRequestsTransformations.log_level',
      issue: 'EventRequestsTransformations.issueId',
    },
    applyFilters: (
      query,
      model,
      filters: {
        transformation_id?: string | string[];
        issue_id?: string | string[];
        webhook_id?: string[];
        log_level: TransformationExecutionLogLevel | TransformationExecutionLogLevel[];
      },
    ) => {
      if (filters.webhook_id && filters.webhook_id.length > 0) {
        query.filters.push(buildFilterField(`${model}.webhookId`, 'equals', filters.webhook_id));
      }

      if (filters.log_level && filters.log_level.length > 0) {
        query.filters.push(
          buildFilterField(
            `${model}.log_level`,
            'equals',
            Array.isArray(filters.log_level) ? filters.log_level : [filters.log_level],
          ),
        );
      }

      if (filters.transformation_id && filters.transformation_id.length > 0) {
        query.filters.push(
          buildFilterField(
            `${model}.transformationId`,
            'equals',
            typeof filters.transformation_id === 'string'
              ? [filters.transformation_id]
              : filters.transformation_id,
          ),
        );
      }

      if (filters.issue_id && filters.issue_id.length > 0) {
        query.filters.push(
          buildFilterField(
            `${model}.issueId`,
            'equals',
            typeof filters.issue_id === 'string' ? [filters.issue_id] : filters.issue_id,
          ),
        );
      }
      return query;
    },
  },
  IgnoredEvents: {
    timeDimentions: ['createdAt'],
    dimentions: {
      cause: 'IgnoredEvents.cause',
    },
    applyFilters: (
      query,
      model,
      filters: {
        cause?: string[];
        webhook_id?: string[];
      },
    ) => {
      if (filters.webhook_id && filters.webhook_id.length > 0) {
        query.filters.push(buildFilterField(`${model}.webhookId`, 'equals', filters.webhook_id));
      }

      if (filters.cause && filters.cause.length > 0) {
        query.filters.push(buildFilterField(`${model}.cause`, 'equals', filters.cause));
      }

      return query;
    },
  },
  Attempts: {
    timeDimentions: ['createdAt', 'deliveredAt'],
    dimentions: {
      destination_id: 'Attempts.destinationId',
    },
    applyFilters: (
      query,
      model,
      filters: {
        status?: AttemptStatus[];
        destination_id?: string[];
      },
    ) => {
      if (filters.destination_id && filters.destination_id.length > 0) {
        query.filters.push(
          buildFilterField(`${model}.destinationId`, 'equals', filters.destination_id),
        );
      }
      if (filters.status && filters.status.length > 0) {
        query.filters.push(buildFilterField(`${model}.status`, 'equals', filters.status));
      }
      return query;
    },
  },
  QueueDepth: {
    timeDimentions: ['timestamp'],
    dimentions: {
      destination_id: 'QueueDepth.destinationId',
    },
    applyFilters: (
      query,
      model,
      filters: {
        destination_id?: string[];
      },
    ) => {
      if (filters.destination_id && filters.destination_id.length > 0) {
        query.filters.push(
          buildFilterField(`${model}.destinationId`, 'equals', filters.destination_id),
        );
      }
      return query;
    },
  },
  EventsPendingTimeseries: {
    timeDimentions: ['timestamp'],
    dimentions: {
      destination_id: 'EventsPendingTimeseries.destinationId',
    },
    applyFilters: (
      query,
      model,
      filters: {
        destination_id?: string[];
      },
    ) => {
      if (filters.destination_id && filters.destination_id.length > 0) {
        query.filters.push(
          buildFilterField(`${model}.destinationId`, 'equals', filters.destination_id),
        );
      }
      return query;
    },
  },
  EventsWithIssues: {
    timeDimentions: ['createdAt'],
    dimentions: { issue: 'EventsWithIssues.issueId' },
    applyFilters: (
      query,
      model,
      filters: {
        issue_id?: string[];
      },
    ) => {
      if (filters.issue_id && filters.issue_id.length > 0) {
        query.filters.push(buildFilterField(`${model}.issueId`, 'equals', filters.issue_id));
      }
      return query;
    },
  },
};

export type CubeModel = keyof typeof CUBE_MODELS;

const formatCubeQuery = (
  model: CubeModel,
  {
    filters = {},
    measure = 'count',
    granularity,
    dimentions = [],
    time_dimention = 'createdAt',
    limit = 10000,
  }: {
    filters?: EventListFiltersProps | RequestListFiltersProps | AttemptFilterProps;
    granularity?: Granularity;
    dimentions?: string[];
    time_dimention?: string;
    measure?: string;
    limit?: number;
  },
): Query => {
  const configs = CUBE_MODELS[model];
  time_dimention = time_dimention || CUBE_MODELS[model].timeDimentions[0];
  if (configs.isInvalid && configs.isInvalid(filters)) {
    return {};
  }

  const query: Query = configs.applyFilters(
    {
      measures: [`${model}.${measure}`],
      filters: [],
      timeDimensions: [],
    },
    model,
    filters,
  );

  let date_range: [string, string] | undefined = undefined;

  if (filters.date?.min || filters.date?.max) {
    if (filters.date.min && filters.date.max) {
      date_range = [toISO(filters.date.min), toISO(filters.date.max)];
    } else if (filters.date.min) {
      date_range = [toISO(filters.date.min), toISO(new Date())];
    } else if (filters.date.max) {
      date_range = [START_DATE, toISO(filters.date.max)];
    }

    query.timeDimensions.push(
      buildTimeDimension(`${model}.${time_dimention}`, granularity, date_range),
    );
  } else if (granularity) {
    query.timeDimensions.push(buildTimeDimension(`${model}.${time_dimention}`, granularity));
  }

  if (dimentions?.length > 0) {
    query.dimensions = dimentions.map((d) => configs.dimentions[d]);
  }

  query.limit = limit;

  return query;
};

export default formatCubeQuery;
