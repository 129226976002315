import { Fragment, MouseEvent, MouseEventHandler, ReactNode, useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { Link as LinkRouter, NavLink, Route, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import useSWR from 'swr';

import APIMethodKeys from '../../../../client/APIMethodKeys';
import { stringifyQuery } from '../../../../utils';
import isEmpty from '../../../../utils/isEmpty';
import { formatPath } from '../../../../utils/path';
import Avatar from '../../../common/base/Avatar';
import Badge from '../../../common/base/Badge';
import Button, { ClickableArea } from '../../../common/base/Button';
import { StyledCardSection } from '../../../common/base/Card';
import Divider from '../../../common/base/Divider';
import Icon from '../../../common/base/Icon';
import Link from '../../../common/base/Link';
import Text from '../../../common/base/Text';
import Tooltip from '../../../common/base/Tooltip';
import Dropdown from '../../../common/Dropdown';
import { Div } from '../../../common/helpers/StyledUtils';
import Search from '../../../common/Search';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { useIsDegradedBanner } from '../../../hooks/useIsDegradedBanner';
import useLocalStorage from '../../../hooks/useLocalStorage';
import useSearchQuery from '../../../hooks/useSearchQuery';
import { DashboardContext } from '../DashboardContext';
import { DateRange } from '../Metrics/MetricDatePicker';
import { ViewsContext, ViewsContextProvider } from '../ViewsContext';
import NavFooter, { StyledBeamerButton } from './NavFooter';
import NavResourceLink from './NavResourceLink';
import TeamSelection from './TeamSelection/TeamSelection';

export const StyledDashboardNav = styled.div(({ theme }) => {
  const degraded_banner_active = useIsDegradedBanner();
  const banner_height = '32px';
  return css`
    display: flex;
    position: sticky;
    top: ${degraded_banner_active ? banner_height : 0};
    max-height: ${degraded_banner_active ? `calc(100vh - ${banner_height})` : '100vh'};
    min-height: ${degraded_banner_active ? `calc(100vh - ${banner_height})` : '100vh'};
    background-color: ${theme.colors.surface.base.background};
    border-right: ${theme.border};
    z-index: ${theme.zindex.sticky + 2};
    position: relative;
  `;
});

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const StyledPrimaryNavLink = styled(LinkRouter)``;

export const StyledNavLinks = styled.div(
  ({ theme }) => css`
    display: flex;
    gap: ${theme.spacing(1)};
    flex-direction: column;

    > a,
    > button,
    > ${StyledBeamerButton} {
      display: flex;
      align-items: center;
      position: relative;
      text-decoration: none;
      padding: ${theme.spacing(1.5)} ${theme.spacing(1.5)} ${theme.spacing(1.5)} ${theme.spacing(2)};
      border-radius: ${theme.radius.normal};

      color: ${theme.colors.on.neutral.tertiary_neutral};
      svg,
      > span {
        color: ${theme.colors.on.neutral.tertiary_neutral};
        fill: ${theme.colors.on.neutral.tertiary_neutral};
      }

      &:hover {
        background-color: ${theme.colors.surface.base.variant_surface};
      }

      &.active {
        background-color: ${theme.colors.surface.base.variant_surface};
        color: ${theme.colors.on.neutral.primary_neutral};
      }

      &:focus-visible {
        outline: none;
        &::before {
          content: '';
          position: absolute;
          top: -3px;
          left: -3px;
          right: -3px;
          bottom: -3px;
          border: 2px solid ${theme.colors.outline.focus.primary};
          border-radius: ${theme.radius.large};
        }
      }
    }

    ${StyledPrimaryNavLink} {
      color: ${theme.colors.on.neutral.primary};
      svg,
      > span {
        color: ${theme.colors.on.neutral.primary};
        fill: ${theme.colors.on.neutral.primary};
      }
    }
  `,
);

const StyledViewsContainer = styled(Div)(
  ({ theme }) => css`
    margin-right: -4px;
    padding-right: 4px;
    margin-bottom: -4px;
    padding-bottom: 4px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: ${theme.pxToRem(15)};
      top: ${theme.pxToRem(8)};
      bottom: ${theme.pxToRem(8)};
      background-color: ${theme.colors.outline.neutral};
      width: 1px;
    }

    ${StyledNavLinks} {
      > a:not(.active) {
        color: ${theme.colors.on.neutral.secondary_neutral};
      }
    }
  `,
);

const StyledActivityIndicator = styled(Div)(
  ({ theme }) => css`
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: ${theme.colors.on.neutral.success};
    margin: 0 ${theme.spacing(1.5)};
  `,
);

export const http_links = [
  {
    key: 'requests',
    path: `/requests`,
    label: 'Requests',
    icon: 'requests' as const,
  },
  {
    key: 'events',
    path: `/events`,
    label: 'Events',
    icon: 'events' as const,
  },
  {
    key: 'bookmarks',
    path: `/bookmarks`,
    label: 'Bookmarks',
    icon: 'bookmarks' as const,
  },
];

const Views = ({ type, prefix }: { type: 'events' | 'requests'; prefix?: 'cli' }) => {
  const { views } = useContext(ViewsContext);

  const [search, setSearch] = useState('');

  const all_views = views.models
    .sort((a, b) => {
      if (a.id === 'cli') return -1;
      if (b.id === 'cli') return 1;
      return a.name.localeCompare(b.name);
    })
    .filter(({ name }) => !search || name.toLowerCase().indexOf(search.toLowerCase()) >= 0);

  return (
    <StyledNavLinks>
      {views.models
        .filter((_, i) => i < 5)
        .map((view) => (
          <NavLink
            key={view.id}
            to={`${prefix ? `/${prefix}` : ''}/${type}/${formatPath(view.name, {
              escape_parentheses: false,
            })}`}>
            <Div w={100} flex={{ align: 'center', justify: 'space-between' }}>
              <Text subtitle size="m" ellipsis>
                {view.name}
              </Text>
              {view?.id === 'cli' && <StyledActivityIndicator />}
            </Div>
          </NavLink>
        ))}
      {views?.models.length > 5 && (
        <Dropdown
          placement="right-start"
          block
          p={0}
          m={{ l: 2 }}
          parent_width={{ min: 248, max: 248 }}
          renderToggle={(opened, toggle) => (
            <StyledNavLinks>
              <ClickableArea highlight={opened} onClick={() => toggle(!opened)}>
                <Div flex={{ align: 'center', justify: 'space-between' }}>
                  <Text muted subtitle size="m" ellipsis>
                    See all {views?.models.length} views...
                  </Text>
                </Div>
              </ClickableArea>
            </StyledNavLinks>
          )}>
          {(toggle) => (
            <>
              <StyledCardSection muted p={{ x: 3, y: 1 }}>
                <Text size="xs" subtitle>
                  Saved Views
                </Text>
              </StyledCardSection>
              <StyledCardSection p={{ x: 3, y: 2 }}>
                <Search focus small value={search} onChange={setSearch} />
              </StyledCardSection>
              <StyledCardSection max_h={{ px: 204 }} scroll>
                {all_views.length === 0 && search && (
                  <Text muted p={{ x: 3, y: 2 }}>
                    No matching views...
                  </Text>
                )}
                {all_views.map((view, i) => (
                  <Fragment key={view.id}>
                    {i !== 0 && <Divider />}
                    <ClickableArea
                      w={100}
                      p={{ x: 3, y: 2 }}
                      onClick={() => toggle(false)}
                      to={`${prefix ? `/${prefix}` : ''}/${type}/${formatPath(view.name, {
                        escape_parentheses: false,
                      })}`}>
                      <Text subtitle size="m" ellipsis>
                        {view.name}
                      </Text>
                    </ClickableArea>
                  </Fragment>
                ))}
              </StyledCardSection>
            </>
          )}
        </Dropdown>
      )}
    </StyledNavLinks>
  );
};

const GetStartedNavLink = styled(StyledNavLinks)`
  padding: 0px;

  & > a {
    padding: 2px 8px;
    padding-right: 2px;
  }
`;

const GetStartedCloseButton = ({
  hide = true,
  onClick,
}: {
  hide?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
}) => {
  return (
    <StyledGetStartedCloseButton hide={hide}>
      <Tooltip tooltip="Dismiss tab" align="right" offset={[-3, 8]}>
        <Button
          on="background"
          minimal
          small
          icon="close"
          p={1}
          h={{ px: 28 }}
          w={{ px: 28 }}
          onClick={onClick}
        />
      </Tooltip>
    </StyledGetStartedCloseButton>
  );
};

const StyledGetStartedCloseButton = styled.div<{ hide: boolean }>`
  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
  width: ${({ hide }) => (hide ? '0px' : 'auto')};

  ${GetStartedNavLink}:hover & {
    visibility: visible;
    width: auto;
  }
`;

const GetStartedBadge = styled(Badge)`
  visibility: visible;
  margin: 4px;
  color: ${({ theme }) => theme.colors.on.neutral.primary}!important;

  ${GetStartedNavLink}:hover & {
    visibility: hidden;
    position: absolute;
  }
`;

const NavSections = ({ title, children }: { title: string; children: ReactNode }) => {
  const [collapsed, setCollapsed] = useLocalStorage(
    `pref:nav-collapsed:${title.toLowerCase()}`,
    false,
  );
  return (
    <>
      <Link
        no_underline
        onClick={() => setCollapsed(!collapsed)}
        flex={{ align: 'center' }}
        m={{ x: 2, t: 4, b: 2 }}
        neutral
        icon={collapsed ? 'arrow_right' : 'arrow_drop_down'}>
        {title}
      </Link>
      {!collapsed && children}
    </>
  );
};

const DashboardNav = () => {
  const {
    subscription,
    setNavCollapsed,
    nav_collapsed,
    organization,
    team,
    get_started_pending_steps,
    show_get_started,
    setShowGetStarted,
    active_cli_sessions,
  } = useContext(DashboardContext);
  const { HookdeckAPI } = useContext(GlobalContext);
  const { pathname } = useLocation();
  const { query } = useSearchQuery<{
    date?: DateRange;
  }>();

  const { data: event_views } = useSWR(APIMethodKeys.views.list({ type: 'events' }), () =>
    HookdeckAPI.views.list({ type: 'events' }),
  );
  const { data: request_views } = useSWR(APIMethodKeys.views.list({ type: 'requests' }), () =>
    HookdeckAPI.views.list({ type: 'requests' }),
  );

  if (active_cli_sessions?.length > 0) {
    event_views;
  }

  const { data: issues_count_response } = useSWR(
    APIMethodKeys.issues.count({ status: 'OPENED' }),
    () => HookdeckAPI.issues.count({ status: 'OPENED' }),
    {
      refreshInterval: 1000 * 60,
    },
  );

  const history = useHistory();

  const issues_count = issues_count_response?.count || 0;

  const closeGetStarted = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setShowGetStarted(false);
    if (pathname === '/get-started') {
      history.push('/');
    }
  };

  return (
    <StyledDashboardNav>
      <ScrollContainer>
        <Div p={{ x: 2, y: 4 }} flex={{ grow: true, direction: 'column' }}>
          <Div flex={{ align: 'center', justify: 'space-between', gap: 2 }}>
            <Dropdown
              block
              placement="bottom-start"
              p={0}
              renderToggle={(opened, toggle) => (
                <ClickableArea
                  on="background"
                  rounded
                  block
                  highlight={opened}
                  flex={{ align: 'center' }}
                  onClick={() => toggle(!opened)}
                  p={{ x: 4, y: 2 }}>
                  <Avatar name={organization!.name} small />
                  <Text m={{ x: 2 }} subtitle muted>
                    /
                  </Text>
                  <Text ellipsis text_wrap={false} subtitle size="l">
                    {team!.name}
                  </Text>
                  <Icon muted icon="expand_all" m={{ l: 'auto' }} p={{ l: 1 }} />
                </ClickableArea>
              )}>
              <TeamSelection />
            </Dropdown>
          </Div>
          <Div p={{ x: 2, t: 6 }}>
            {show_get_started !== false && (
              <Div m={{ b: 6 }}>
                <GetStartedNavLink>
                  <NavLink to="/get-started">
                    <Icon left icon="grid_view" pointer p={0.5} />
                    <Text subtitle size="m" w={100} flex={{ justify: 'space-between' }}>
                      Get Started
                    </Text>
                    {pathname === '/get-started' ? (
                      <GetStartedCloseButton hide={false} onClick={closeGetStarted} />
                    ) : (
                      <>
                        {get_started_pending_steps > 0 && (
                          <GetStartedBadge subtle>{get_started_pending_steps}</GetStartedBadge>
                        )}
                        <GetStartedCloseButton onClick={closeGetStarted} />
                      </>
                    )}
                  </NavLink>
                </GetStartedNavLink>
              </Div>
            )}
            <NavSections title="Configure">
              <StyledNavLinks>
                <NavLink to="/connections">
                  <Icon left icon="connections" pointer p={0.5} />
                  <Text subtitle size="m">
                    Connections
                  </Text>
                </NavLink>
                <NavResourceLink resource="sources" />
                <NavResourceLink resource="destinations" />
                <NavResourceLink resource="transformations" />
              </StyledNavLinks>
            </NavSections>
            <NavSections title="Monitor">
              <StyledNavLinks>
                <NavLink to="/issues">
                  <Icon left icon="issues" pointer p={0.5} />
                  <Text subtitle size="m" w={100} flex={{ justify: 'space-between' }}>
                    Issues
                    {issues_count > 0 && (
                      <Badge danger subtle>
                        {issues_count || 1}
                      </Badge>
                    )}
                  </Text>
                </NavLink>
                <NavLink to="/issues-triggers">
                  <Icon left icon="issue_trigger" pointer p={0.5} />
                  <Text subtitle size="m" w={100} flex={{ justify: 'space-between' }}>
                    Issue Triggers
                  </Text>
                </NavLink>
                <NavLink
                  to={`/metrics${
                    pathname.includes('metrics') && !isEmpty(query?.date)
                      ? `?${stringifyQuery({ date: query.date })}`
                      : ''
                  }`}
                  isActive={() => pathname === '/metrics'}>
                  <Icon left icon="metrics" pointer p={0.5} />
                  <Text subtitle size="m" w={100} flex={{ justify: 'space-between' }}>
                    Metrics
                  </Text>
                  {!subscription?.features?.includes('metrics') && (
                    <Icon muted icon="lock" m={{ r: 0.5 }} />
                  )}
                </NavLink>
              </StyledNavLinks>
            </NavSections>
            <NavSections title="Inspect">
              <StyledNavLinks>
                {http_links.map((link) => (
                  <Fragment key={link.key}>
                    <NavLink to={link.path} isActive={() => pathname === link.path}>
                      <Icon left icon={link.icon} pointer p={0.5} />
                      <Text subtitle size="m" ellipsis>
                        {link.label}
                      </Text>
                    </NavLink>
                    {request_views && link.key === 'requests' && request_views?.count > 0 && (
                      <ViewsContextProvider type="requests" redirect_root="/requests">
                        <Route path="/requests">
                          <StyledViewsContainer flex={{ direction: 'column' }}>
                            <Route path="/requests">
                              <Div m={{ l: 6 }} flex={{ direction: 'column' }}>
                                <Views type="requests" />
                              </Div>
                            </Route>
                          </StyledViewsContainer>
                        </Route>
                      </ViewsContextProvider>
                    )}
                    {event_views && link.key === 'events' && event_views?.count > 0 && (
                      <ViewsContextProvider type="events" redirect_root="/events">
                        <Route path="/events">
                          <StyledViewsContainer flex={{ direction: 'column' }}>
                            <Route path="/events">
                              <Div m={{ l: 6 }} flex={{ direction: 'column' }}>
                                <Views type="events" />
                              </Div>
                            </Route>
                          </StyledViewsContainer>
                        </Route>
                      </ViewsContextProvider>
                    )}
                  </Fragment>
                ))}
              </StyledNavLinks>
            </NavSections>
          </Div>
        </Div>
        <NavFooter />
      </ScrollContainer>
    </StyledDashboardNav>
  );
};

export default DashboardNav;
